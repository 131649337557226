<template>
  <div class="singup-investigator">
    <h1>Registro de Investigador</h1>
    <div class="information input-content">
      <div class="information-content label-top">
        <label for="name">Nombres</label>
        <input type="text" value="" name="name" />
      </div>
      <div class="information-content label-top">
        <label for="lastname">Apellidos</label>
        <input type="text" value="" name="lastname" />
      </div>
    </div>
    <div class="input-content full-input label-top">
      <label for="investigator-email">Email</label>
      <input type="text" value="" name="investigator-email" />
    </div>
    <div class="input-content full-input label-top">
      <label for="password">Contraseña</label>
      <input type="text" value="" name="password" />
      <label for="confirm-password">Confirmar Contraseña</label>
      <input type="text" value="" name="confirm-password" />
    </div>
    <input class="submit" type="submit" value="Sing Up" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.singup-investigator {
  text-align: left;
  h1 {
    text-align: center;
  }
  .information {
    display: flex;
    width: 100%;
    .information-content {
      width: 50%;
    }
  }
  input {
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #d0d0d0;
    height: 70px;
    border-radius: 35px;
    padding: 20px 30px;
    &:focus {
      border-color: #222ac3;
    }
  }
  .label-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .submit {
    background: #222ac3;
    color: white;
    font-size: 15px;
    font-family: Poppins;
  }
}
</style>
